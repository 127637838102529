import React, { useState, useRef, useEffect } from 'react';
import './ChatBox.css';
import chatbot from '../../assets/images/chatbot.png';
import guest from '../../assets/images/guest.png';
import typing from '../../assets/images/loading-type.svg';

const ChatBox = () => {
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState(false);
  const chatRoomRef = useRef(null);

  const typeMessage = (message, callback) => {
    let index = 0;
    const typingSpeed = 50; // Speed in milliseconds

    // Find the last bot message and start typing effect
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      
      if (lastMessage && lastMessage.sender === 'bot') {
        lastMessage.text = ''; // Clear existing text
      }

      return updatedMessages;
    });
    setLoading(false);

    const typeInterval = setInterval(() => {
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];

        if (lastMessage && lastMessage.sender === 'bot') {
          if (index < message.length) {
            lastMessage.text += message[index];
            index++;
          } else {
            clearInterval(typeInterval);
            callback(); // Callback to hide the loading indicator
          }
          return updatedMessages;
        } else {
          return prevMessages;
        }
      });
    }, typingSpeed);
  };

  const sendMessage = async () => {
    if (messageText.trim()) {
      setMessages([...messages, { text: messageText, sender: 'user' }]);
      setMessageText('');
      setLoading(true);

      const url = new URL('https://futurum-chatbot-service.onrender.com/api/chat-bot/help');
      url.searchParams.append('query', messageText);

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.text();

        setMessages(prevMessages => [
          ...prevMessages,
          { text: '', sender: 'bot' } // Placeholder for the bot's response
        ]);

        typeMessage(data || 'Sorry, I could not understand.', () => {
          setLoading(false); // Hide loading indicator when typing is done
        });
      } catch (error) {
        console.error('Error fetching bot response:', error);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Error fetching response. Please try again.', sender: 'bot' }
        ]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (chatRoomRef.current) {
      chatRoomRef.current.scrollTop = chatRoomRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      <div className="chat-container">
        <div className="chatname">
          <div className="avatar-wrapper avatar-big">
            <img width={'30px'} src={chatbot} alt="avatar" />
          </div>
          <span className="chatname">Futurbot</span>
        </div>
        <div className="">
          <div className="chat-room" ref={chatRoomRef}>
            <div className="message-slider">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`hhh ${message.sender === 'bot' ? 'bot-message' : 'user-message'}`}
                >
                  <div className="avatar-wrapper avatar-small">
                    <img
                      width={'30px'}
                      src={message.sender === 'user' ? guest : chatbot}
                      alt="avatar"
                    />
                  </div>
                  <div
                    className={`bubble ${message.sender === 'bot' ? 'bubble-light' : 'bubble-dark'}`}
                  >
                    {message.text}
                  </div>
                </div>
              ))}
              {loading && (
                <div className="hhh bot-message">
                  <div className="avatar-wrapper avatar-small">
                    <img width={'30px'} src={chatbot} alt="avatar" />
                  </div>
                  <div className="bubble bubble-light typing-indicator">
                    <img src={typing} width={30} alt='loading' />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="type-area">
          <div className="input-container">
            <input
              className="chat-input"
              type="text"
              id="inputText"
              placeholder="Type messages here..."
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
            <button className="search-header__button" onClick={sendMessage}>
              <svg
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="search-header__icon"
              >
                <path
                  opacity="0.15"
                  d="M20 4L3 11L10 14L13 21L20 4Z"
                  fill="#FFFFFF"
                />
                <path
                  d="M20 4L3 11L10 14M20 4L13 21L10 14M20 4L10 14"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;
