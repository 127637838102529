import React, { useEffect } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import robothand from "./../../assets/images/robot arm.png";
import humanhand from "./../../assets/images/human arm.png";
import power from "./../../assets/images/power.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";
import Footer from "../../Components/Footer/Footer";
import BgAnim from "../../shared/bg-anim/BgAnim";
import ChatBox from "../../Components/chatbox/Chatbox";
import obj1 from "../../assets/images/obj1.png";
import aibuilder from "../../assets/images/aibuilder.png";
import aisolution from "../../assets/images/aisolution.png";
import nft from "../../assets/images/animnft.json";
import crypto from "../../assets/images/animncrypto.json";
import Lottie from "lottie-react";
import Button from "../../shared/button/Button";

const Home = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div className="home">
      <BgAnim />
      <div className="news">
        <BgAnim />
        <h6 className="n">{t('new_updates')}</h6> {/* Use translation */}
        <svg
          className="nn"
          width="19"
          height="26"
          viewBox="0 0 19 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 67">
            <path
              id="Vector"
              d="M9.5 0C8.60252 5.25001 7.87497 6.03349 3 7C7.87501 7.96651 8.60252 8.74999 9.5 14C10.3975 8.74999 11.125 7.96651 16 7C11.125 6.03349 10.3974 5.25001 9.5 0Z"
              fill="#9F9F9F"
            />
            <path
              id="Vector_2"
              d="M14.5 15C13.8787 19.125 13.375 19.7407 10 20.5C13.375 21.2594 13.8787 21.8751 14.5 26C15.1213 21.8751 15.6251 21.2593 19 20.5C15.625 19.7407 15.1213 19.125 14.5 15Z"
              fill="#9F9F9F"
            />
            <path
              id="Vector_3"
              d="M4.50002 14C3.87875 17 3.37497 17.4478 0 18C3.37497 18.5523 3.87875 19 4.50002 22C5.1213 19 5.62507 18.5522 9 18C5.62498 17.4478 5.12134 17 4.50002 14Z"
              fill="#9F9F9F"
            />
          </g>
        </svg>
      </div>
      <h1 className="titlehome">{t('title')}</h1> {/* Use translation */}
      
      <div className="arms">
        <div data-aos="fade-right" className="robothand">
          <img src={robothand} alt="robot hand" />
        </div>
        <div className="mid">
          <div>
            <svg
              className="powerr"
              xmlns="http://www.w3.org/2000/svg"
              width="93"
              height="106"
              viewBox="0 0 93 106"
              fill="none"
            >
              {/* SVG content */}
            </svg>
          </div>
          <img className="power" src={obj1} alt="" />
        </div>
        <div className="chathome">
          <ChatBox />
        </div>
        <div data-aos="fade-left" className="humanhand">
          <img src={humanhand} alt="human hand" />
        </div>
      </div>

      <div className="aboutus">
        <div data-aos="fade-left" className="aisolution">
          <div className="solution">
            <h3>{t('ai_builder')}</h3> {/* Use translation */}
            <h4>{t('ai_builder_desc')}</h4> {/* Use translation */}
            <div className="letsbut">
              <Button className="signup-button">{t('button_lets_go')}</Button>
            </div>
          </div>
          <img className="aisol" src={aibuilder} alt="AI builder" />
        </div>

        <div data-aos="fade-right" className="aibuilder">
          <div className="solution">
            <h3>{t('ai_solution')}</h3> {/* Use translation */}
            <h4>{t('ai_solution_desc')}</h4> {/* Use translation */}
            <div className="letsbut">
              <Button className="signup-button">{t('button_lets_go')}</Button>
            </div>
          </div>
          <img className="aisol" src={aisolution} alt="AI solution" />
        </div>

        <div data-aos="fade-left" className="crypto">
          <div className="solution">
            <h3>{t('crypto_exchange')}</h3> {/* Use translation */}
            <h4>{t('crypto_exchange_desc')}</h4> {/* Use translation */}
            <div className="letsbut">
              <Button className="signup-button">{t('button_lets_go')}</Button>
            </div>
          </div>
          <Lottie animationData={crypto} />
        </div>

        <div data-aos="fade-right" className="nft">
          <div className="solution">
            <h3>{t('nft_exchange')}</h3> {/* Use translation */}
            <h4>{t('nft_exchange_desc')}</h4> {/* Use translation */}
            <div className="letsbut">
              <Button className="signup-button">{t('button_lets_go')}</Button>
            </div>
          </div>
          <Lottie animationData={nft} />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
