import React, { useState, useEffect } from 'react';
import futurum from "./../../../assets/images/futurum.png";
import './DashboardNav.css';
import Button from '../../../shared/button/Button';
import SearchInput from "../../../shared/search/Search";
import { useHistory } from "react-router-dom";
import UpgradeButton from '../UpgradeButton/UpgradeButton';
import Notification from '../icons/Notification';
import ProfileSection from '../ProfileSection/ProfileSection';
import { useTranslation } from "react-i18next"; // Import translation hook
import LanguageSwitcher from '../../../shared/LanguageSwitcher/LanguageSwitcher';

const DashboardNav = () => {
    const history = useHistory();
    const { t } = useTranslation(); // Initialize translation hook
    const [buttonText, setButtonText] = useState(t('get_started'));

    const handleResize = () => {
        if (window.innerWidth <= 580) {
            setButtonText(t('get_started'));
        } else {
            setButtonText(t('get_started'));
        }
    };

    useEffect(() => {
        handleResize(); // Set initial value
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [t]);

    const handleHome = () => {
        history.push('/');
    };
    const handleLogin = () => {
        history.push('/login');
    };
    const handleRegister = () => {
        history.push('/register');
    };

    return (
        <header className="header">
            <div className="contain">
                <div className="logo" onClick={handleHome}>
                    <img src={futurum} alt="futurum" />
                </div>
                <div className="searchInput">
                    <SearchInput placeholder={t('search_placeholder')} /> {/* Translated placeholder */}
                </div>
                <div className="rightd">
                    <ProfileSection />
                    <LanguageSwitcher></LanguageSwitcher>
                    <Notification />
                </div>
            </div>
        </header>
    );
};

export default DashboardNav;
