
import React from 'react';
import './Button.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Button = ({ onClick, children }) => {
  const history = useHistory();

  return (
    <div className="cont">
      <button className="butt" onClick={onClick}>{children}</button>
    </div>

  );
}

export default Button;
