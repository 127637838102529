import React from 'react';
import './payment.css';

const Step1ChoosePlan = ({ plan, setPlan, nextStep }) => {
    const handlePlanSelection = (selectedPlan) => {
        setPlan(selectedPlan);
        nextStep();
    };

    return (
        <div className='step'>
            <h5 className='stepname'>
                We’ve got different packages for you <br />
                Choose the perfect one
            </h5>
            <div className='plans'>
                <div className='plan' id='bronze'>
                    <h3>Package 1 <br />10$/M</h3>
                    <h5>Functionalities:</h5>
                    <p>Limited</p>
                    <h5>Number of AI Models:</h5>
                    <p>1</p> 
                    <h5>Customer</h5>
                    <p>Standard</p> 
                    <button className='buynow' onClick={() => handlePlanSelection('bronze')}>Buy Now</button>
                </div>
                <div className='plan' id='silver'>
                    <h3>Package 2 <br />20$/M</h3>
                    <h5>Functionalities:</h5>
                    <p>Unlimited</p>
                    <h5>Number of AI Models:</h5>
                    <p>3</p> 
                    <h5>Customer</h5>
                    <p>Standard</p> 
                    <button className='buynow' onClick={() => handlePlanSelection('silver')}>Buy Now</button>
                </div>
                <div className='plan' id='gold'>
                    <h3>Package 3 <br />30$/M</h3>
                    <h5>Functionalities:</h5>
                    <p>Unlimited</p>
                    <h5>Number of AI Models:</h5>
                    <p>5</p> 
                    <h5>Customer</h5>
                    <p>Personalized</p> 
                    <button className='buynow' onClick={() => handlePlanSelection('gold')}>Buy Now</button>
                </div>
            </div>
        </div>
    );
};

export default Step1ChoosePlan;
