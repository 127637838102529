import "./App.css";
import Home from "./screens/Home/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./Components/NotFound";
import Navbar from "./Components/Navbar/Navbar";
import Login from "./screens/auth/login/Login";
import Register from "./screens/auth/register/Register";
import UserDashboard from "./screens/UserDashboard/UserDashboard";
import PrivateRoute from "./Components/PrivateRoute";
import Settings from "./Components/Dashboard/Settings/Settings";
import BusinessSolution from "./Components/Dashboard/Tools/BusinessSolution/BusinessSolution";
import { Provider } from "react-redux";
import store from "./app/store";
function App() {
  return (
    <Provider store={store}>
    <Router>
      <div className="App">
        {/* <BallsMenu /> */}
        <Switch>
          <Route exact path="/">
            <Navbar></Navbar>
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <PrivateRoute path="/dashboard" component={UserDashboard} />
          <PrivateRoute path="/business-solution" component={BusinessSolution} />
          <Route exact path="/settings">
              <Settings />
            </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
      {/* <Footer /> */}
    </Router>
    </Provider>
  );
}

export default App;
