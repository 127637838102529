import React from 'react';
import PayPalButton from '../paypalButton/PayPalButton';

const Step3ConfirmAndPay = ({ plan, prevStep }) => {
  return (
    <div className='payme'>
      <h5>Step 3: Confirm and Pay</h5>
      <p>You have selected the {plan} plan.</p>
      <div className="paypal-button">
        <PayPalButton plan={plan} />
      </div>
      <br />
      <button className='buynow' onClick={prevStep}>Back</button>
    </div>
  );
};

export default Step3ConfirmAndPay;
