const CHAT_URL = process.env.CHAT_URL;

export const sendChatMessage = async (query) => {
  const url = new URL(CHAT_URL);
  url.searchParams.append('query', query);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error fetching bot response:', error);
    throw error;
  }
};
