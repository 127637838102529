import React from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";

// Ensure the PayPal SDK script is loaded with client ID
const PayPalButton = ({ plan }) => {
  return (
    <PayPalButtons
      createOrder={(data, actions) => {
        return fetch('https://futurum-chatbot-service.onrender.com/paypal/create-payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ plan }), // Send selected plan to server
        })
          .then((response) => response.json())
          .then((orderData) => {
            return orderData.id; // Use the order ID returned by your server
          });
      }}
      onApprove={(data, actions) => {
        return fetch('https://futurum-chatbot-service.onrender.com/paypal/capture-payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ orderID: data.orderID }),
        })
          .then((response) => response.json())
          .then((details) => {
            alert('Transaction completed by ' + details.payer.name.given_name);
          });
      }}
      onError={(err) => {
        console.error(err);
        alert('An error occurred with your payment.');
      }}
    />
  );
};

export default PayPalButton;
