import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css'; // Make sure this path is correct

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  // Define language options
  const languages = [
    { code: 'en', name: '', flag: 'https://flagicons.lipis.dev/flags/4x3/us.svg' },
    { code: 'ar', name: '', flag: 'https://flagicons.lipis.dev/flags/4x3/sa.svg' },
    { code: 'fr', name: '', flag: 'https://flagicons.lipis.dev/flags/4x3/fr.svg' }
  ];

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang); // Save the language choice to localStorage
    setIsVisible(false); // Hide the dropdown after changing the language
  };

  // Find current language data
  const currentLanguage = languages.find(lang => lang.code === i18n.language);

  return (
    <div id="wrapper">
      <div className="ln">
        <button
          className="dropdown-button square"
          title={currentLanguage ? currentLanguage.name : 'Select Language'}
          onClick={() => setIsVisible(!isVisible)}
        >
          {currentLanguage && (
            <>
              <img src={currentLanguage.flag} alt={`flag-${currentLanguage.code}`} />
              <p>
                <span className="language-code">{currentLanguage.code.toUpperCase()}</span>
                <span className="language-name">{currentLanguage.name}</span>
              </p>
            </>
          )}
        </button>
        {isVisible && (
          <div className="dropdown-menu">
            {languages
              .filter(lang => lang.code !== i18n.language) // Exclude the current language from the dropdown
              .map(lang => (
                <button
                  key={lang.code}
                  className="square"
                  title={lang.name}
                  onClick={() => handleLanguageChange(lang.code)}
                >
                  <img src={lang.flag} alt={`flag-${lang.code}`} />
                  <p>
                    <span className="language-code">{lang.code.toUpperCase()}</span>
                    <span className="language-name">{lang.name}</span>
                  </p>
                </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
