import './HomeDashboard.css';
import kpi from '../../../assets/images/Frame 22.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next'; // Import the translation hook

const HomeDashboard = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation(); // Initialize the translation hook

    const goToBusiness = () => {
        history.push('/dashboard/business-solution');
    };

    // Array of AI models
    const aiModels = [
        { title: 'Predictive Analytics' },
        { title: 'Natural Language Processing' },
        { title: 'Machine Learning' },
        { title: 'Decision Management' },
        { title: 'Robotic Process Automation' },
        { title: 'Virtual Agents' },
        { title: 'Content Creation' },
        { title: 'Marketing Automation' },
        { title: 'Knowledge Work Automation' },
        { title: 'Streamlining Operations' },
        { title: 'Optimizing Supply Chain' },
    ];

    // Determine if the current language is Arabic (RTL)
    const isRtl = i18n.language === 'ar';

    return (
        <div className={`d ${isRtl ? 'rtl' : ''}`}>
            <div className='d1'>
                <h3 className='dash-title'>{t('home_title')}</h3> {/* Translated Home title */}
                <div className="p1">
                    <div className='grad-cont'>
                        <div className='grad-bg'>
                            <img src={kpi} width={'100%'} alt="KPIs" />
                            <h5 className='dash-title2'>{t('kpis_title')}</h5> {/* Translated KPIs title */}
                        </div>
                    </div>
                </div>
                <h3 className='dash-title'>{t('featured_tools_title')}</h3> {/* Translated Featured Tools */}
                <div className='p2'>
                    <div className='ai' onClick={goToBusiness}>
                        <div className='grad-bg'>
                            <h5 className='ai-name'>{t('business_solution_title')}</h5> {/* Translated Business Solution title */}
                            <p className='ai-details'>{t('business_solution_details')}</p> {/* Translated Business Solution details */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeDashboard;
