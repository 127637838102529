import React from 'react';
import './Menu.css';
import dashboard from '../../../assets/icons/dashboard.png';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Menu = () => {
    const { t } = useTranslation(); // Initialize useTranslation hook
    const history = useHistory();
    const location = useLocation();

    const handleHome = () => {
        history.push('/dashboard');
    };

    const handleSettings = () => {
        history.push('/dashboard/settings');
    };

    return (
        <div className="men">
            <div onClick={handleHome} className={`${location.pathname === '/dashboard' ? 'cont-menu' : 'cont-menu-off'}`}>
                <div className='menu-but'>
                    <img src={dashboard} alt="Dashboard Icon" width={20} />
                    <p className="m1">{t('menu_home')}</p> {/* Use t('menu_home') */}
                </div>
            </div>
        </div>
    );
}

export default Menu;
