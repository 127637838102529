
import React from 'react';
import './UpgradeButton.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const UpgradeButton = ({ onClick}) => {
  const history = useHistory();

  return (
      <button className="buttup" onClick={onClick}>Upgrade Plan</button>

  );
}

export default UpgradeButton;
