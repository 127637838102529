import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './app/store';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import i18n from './i18n'; // Import i18n from your i18n.js
import AOS from 'aos'; // Import AOS
import 'aos/dist/aos.css'; // Import AOS styles

// Initialize AOS
AOS.init();

const initialOptions = {
  clientId: "Aaj7mRXTRUkJv0BuPcMUcc3LqzD_GPNW1Ihds9lQA4JhXrSiLjL9vCpWK9QWTDnzl-NJCco-d25NqpJq", // Replace with your PayPal client ID
  currency: "USD" // Set your preferred currency
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PayPalScriptProvider options={initialOptions}>
      <App />
    </PayPalScriptProvider>
  </Provider>
);
