import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isTokenExpired from '../features/auth/tokenCheck';

const PrivateRoute = ({ component: Component, ...rest }) => {
    // Access auth state and safely handle undefined state
    const { user } = useSelector((state) => state.auth || {});  // Ensure state.auth is defined
    const token = localStorage.getItem('token');

    return (
        <Route
            {...rest}
            render={(props) =>
                token && !isTokenExpired(token) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/register" />
                )
            }
        />
    );
};

export default PrivateRoute;
