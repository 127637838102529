import React, { useState, useEffect } from "react";
import "./MyProfile.css";
import Button from "../../../shared/button/Button";
import axios from "axios";
import { useTranslation } from 'react-i18next'; // Import the translation hook

const MyProfile = () => {
    const [imagePreview, setImagePreview] = useState("");
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(""); // Assuming you want to keep it
    const [file, setFile] = useState(null);

    const { t } = useTranslation(); // Initialize the translation hook

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem("token");
            try {
                const response = await axios.get("http://localhost:8080/auth/profile", {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    const user = response.data;
                    console.log("Fetched user data:", user);

                    setUserName(user.userName || "");
                    setFirstName(user.firstName || "");
                    setLastName(user.lastName || "");
                    setEmail(user.email || "");

                    if (user.profileImage) {
                        setImagePreview(user.profileImage);
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            const reader = new FileReader();
            reader.onload = (event) => {
                setImagePreview(event.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const userData = {
            userName,
            email,
            firstName,
            lastName,
        };

        const token = localStorage.getItem("token");

        try {
            const response = await axios.put("http://localhost:8080/auth/profile", userData, {
                headers: {
                    "Content-Type": "application/json", // Set the content type to application/json
                    "Authorization": `Bearer ${token}`, // Include the JWT token here
                },
            });

            if (response.status === 200) {
                alert(t('profile_update_success')); // Use translated success message
            }
        } catch (error) {
            console.error("There was an error updating the profile!", error);
            alert(t('profile_update_error')); // Use translated error message
        }
    };

    return (
        <>  
            <div className="d1">
                <h3 className="dash-title">{t('update_profile')}</h3> {/* Translated title */}
                <div className="profile-container">
                    <div className="updt">
                        <div className="grad-bg">
                            <div className="prof-img">
                                <div className="circle">
                                    <label htmlFor="file-upload" className="upload-label">
                                        <span className="upload-icon"></span>
                                        <span style={{
                                            fontSize: "10px",
                                            color: "#666",
                                            textAlign: "center",
                                            lineHeight: "1.5"
                                        }} className="upload-text">{t('update_profile_image')}</span> {/* Translated text */}
                                    </label>
                                    <input
                                        id="file-upload"
                                        type="file"
                                        onChange={handleImageChange}
                                    />
                                    {imagePreview && (
                                        <img
                                            id="preview-image"
                                            src={imagePreview}
                                            alt={t('profile_preview')} // Translated alt text
                                        />
                                    )}
                                </div>
                                <p style={{
                                    fontSize: "14px",
                                    color: "#666",
                                    marginTop: "10px",
                                    textAlign: "center",
                                    lineHeight: "1.5"
                                }}>
                                    {t('file_upload_instructions')} {/* Translated instructions */}
                                </p>
                            </div>
                            <div className="prof-info">
                                <div className="form__group field">
                                    <input
                                        placeholder={t('username_placeholder')} // Translated placeholder
                                        className="form__field"
                                        type="text"
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                    <label htmlFor="name" className="form__label">{t('username')}</label> {/* Translated label */}
                                </div>
                                <div className="form__group field">
                                    <input
                                        placeholder={t('first_name_placeholder')} // Translated placeholder
                                        className="form__field"
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <label htmlFor="name" className="form__label">{t('first_name')}</label> {/* Translated label */}
                                </div>
                                <div className="form__group field">
                                    <input
                                        placeholder={t('last_name_placeholder')} // Translated placeholder
                                        className="form__field"
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    <label htmlFor="name" className="form__label">{t('last_name')}</label> {/* Translated label */}
                                </div>
                                <div className="form__group field">
                                    <input
                                        placeholder={t('email_placeholder')} // Translated placeholder
                                        className="form__field"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label htmlFor="name" className="form__label">{t('email')}</label> {/* Translated label */}
                                </div>
                                <div style={{
                                    marginTop: "10px",
                                    marginLeft: '40px'
                                }}>
                                    <Button onClick={handleSubmit}>{t('save_changes')}</Button> {/* Translated button text */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyProfile;
