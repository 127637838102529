import React, { useState, useEffect, useRef } from "react";
import "./ProfileSection.css";
import UpgradeButton from "../UpgradeButton/UpgradeButton";
import { useDispatch } from "react-redux";
import { logout } from '../../../features/auth/authSlice';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


function getUser() {
    let user = localStorage.getItem('user');
    if (user) {
        user = JSON.parse(user);
        console.log(user.firstName);
    } else {
        user = null;
    }
    return user;
}
const ProfileSection = () => {
    const [user, setUser] = useState(getUser);
    const dispatch = useDispatch();
    const history = useHistory();

    const [menuOpen, setMenuOpen] = useState(false);
    const profileRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Close the menu if clicking outside the profile section
    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };
    const handleLogout = () => {
        dispatch(logout());
        history.push('/')
      };
      const handlePlan = () => {
        history.push('/dashboard/settings')
      };
      const handleProfile = () => {
        history.push('/dashboard/update-profile')
      };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className="profile-section"
            onClick={toggleMenu}
            ref={profileRef}
        >
            <img src="https://via.placeholder.com/40" alt="Profile Image" />
            <div className="flech">
                <svg width="15" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 2L10.5 12L2 2" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            {menuOpen && (
                <div className="menu">
                    <div className="menu-item-info">
                        <img src="https://via.placeholder.com/48" alt="Profile Image" />
                        <div className="infouser">
                            <p> {user.userName}</p>
                            <p>{user.email}</p>
                        </div>
                    </div>
                    <svg width="220" height="1" viewBox="0 0 259 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="259" y1="0.5" y2="0.5" stroke="#C6C6C6" />
                    </svg>

                    <div className="menu-item" onClick={handleProfile}>Profile</div>
                    <div className="menu-item" onClick={handleLogout}>Logout</div>
                    <svg width="220" height="1" viewBox="0 0 259 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="259" y1="0.5" y2="0.5" stroke="#C6C6C6" />
                    </svg>
                    <UpgradeButton onClick={handlePlan} className="signup-button" ></UpgradeButton>

                </div>
            )}
        </div>
    );
};

export default ProfileSection;
