import "./Login.css";
import futurum from "./../../../assets/images/futurum.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Login = ({ userName, password, onChange, onSubmitLogin, isError, isLoading }) => {
  return (
      <div className="flip-card__front">
          <div className="title">Log in</div>
          {isError && (
              <p id='overlay'>Invalid Credentials</p>
          )}
          <form className="flip-card__form" onSubmit={onSubmitLogin}>
              <input className="flip-card__input" name="userName" placeholder="Username" type="text" value={userName} onChange={onChange} />
              <input className="flip-card__input" name="password" placeholder="Password" type="password" value={password} onChange={onChange} />
              <div className="login-with">
                  {/* Social login buttons */}
              </div>
              <button className="flip-card__btn">
                  {isLoading ? 'loading...' : 'Login'}
              </button>
          </form>
      </div>
  );
};

export default Login;