import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const apiClient = axios.create({
    baseURL: API_URL,
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const registerUser = async (userData) => {
    try {
        const response = await apiClient.post('auth/register', userData);
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('API error:', error.response.data);
        } else if (error.request) {
            console.error('Network error:', error.request);
        } else {
            console.error('Error:', error.message);
        }
        throw error;
    }
};

const loginUser = async (credentials) => {
    try {
        const response = await apiClient.post('auth/login', credentials);
        return response.data;
    } catch (error) {
        if (error.response) {
            console.error('API error:', error.response.data);
        } else if (error.request) {
            console.error('Network error:', error.request);
        } else {
            console.error('Error:', error.message);
        }
        throw error;
    }
};

export { registerUser, loginUser };
