import React, { useState } from 'react';
import Step1ChoosePlan from '../../../shared/PaymentProcess/Step1ChoosePlan';
import Step2FillInformation from '../../../shared/PaymentProcess/Step2FillInformation';
import Step3ConfirmAndPay from '../../../shared/PaymentProcess/Step3ConfirmAndPay';


const Settings = () => {
  const [step, setStep] = useState(1);
  const [plan, setPlan] = useState('bronze');

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  return (
    <div>
    
      {step === 1 && (
        <Step1ChoosePlan plan={plan} setPlan={setPlan} nextStep={nextStep} />
      )}
      {step === 2 && (
        <Step2FillInformation nextStep={nextStep} prevStep={prevStep} />
      )}
      {step === 3 && (
        <Step3ConfirmAndPay plan={plan} prevStep={prevStep} />
      )}
    </div>
  );
};

export default Settings;
