import React, { useState } from 'react';

const Step2FillInformation = ({ nextStep, prevStep }) => {
  const [paymentMethod, setPaymentMethod] = useState('paypal');

  return (
    <div>
      <h5>Step 2: Fill Information and Choose Payment Method</h5>
      <div>
        <label>
          <input 
            type="radio" 
            name="paymentMethod" 
            value="paypal" 
            checked={paymentMethod === 'paypal'} 
            onChange={(e) => setPaymentMethod(e.target.value)} 
          />
          PayPal
        </label>
        {/* Future payment methods can be added here */}
      </div>
      <br />
      <button className='buynow' onClick={prevStep}>Back</button>
      <button className='buynow' onClick={nextStep}>Next</button>
    </div>
  );
};

export default Step2FillInformation;
