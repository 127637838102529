
import React from 'react';
import './BgAnim.css';

const BgAnim = ({ onClick, children }) => {
  return (
    <div className="absolute">
      <div className="absolute inset-e justify-center">
        <div className="bg-shape1 bg-teal opacity-50 bg-blur">
        </div>
        <div className="bg-shape2 bg-primary opacity-50 bg-blur">
        </div>
        <div className="bg-shape3 bg-purple opacity-50 bg-blur">
        </div>
        <div className="absolute inset-e justify-center">
      </div>
      </div>
      <div className="absolutee inset-e justify-center">
        <div className="bg-shape4 bg-teal opacity-50 bg-blur">
        </div>
        <div className="bg-shape5 bg-primary opacity-50 bg-blur">
        </div>
        <div className="bg-shape6 bg-purple opacity-50 bg-blur">
        </div>
      </div>
    </div>
    
  );
}

export default BgAnim;
