import { Route, Switch } from "react-router-dom";
import DashboardNav from "../../Components/Dashboard/DashboardNav/DashboardNav";
import './UserDashboard.css';
import Menu from "../../Components/Dashboard/Menu/Menu";
import HomeDashboard from "../../Components/Dashboard/HomeDashboard/HomeDashboard";
import Settings from "../../Components/Dashboard/Settings/Settings";
import BusinessSolution from "../../Components/Dashboard/Tools/BusinessSolution/BusinessSolution";
import MyProfile from "../../Components/Dashboard/MyProfile/MyProfile";
import { useTranslation } from "react-i18next";

const UserDashboard = () => {
  const { t, i18n } = useTranslation(); // Initialize translation hook

  // Determine the direction of the language (LTR or RTL)
  const isRtl = i18n.language === 'ar';

  return (
    <>
      <div>
        <DashboardNav />
        <div className={`container ${isRtl ? 'rtl' : 'ltr'}`}>
          <div className="menuu">
            <Menu />
          </div>
          <div className="content">
            {/* Nested Routes */}
            <Switch>
              <Route
                exact
                path="/dashboard"
                component={() => <HomeDashboard title={t('dashboard')} />} // Pass translated text
              />
              <Route
                exact
                path="/dashboard/settings"
                component={() => <Settings title={t('settings')} />} // Pass translated text
              />
              <Route
                exact
                path="/dashboard/business-solution"
                component={() => <BusinessSolution title={t('business_solution')} />} // Pass translated text
              />
              <Route
                exact
                path="/dashboard/update-profile"
                component={() => <MyProfile title={t('my_profile')} />} // Pass translated text
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
